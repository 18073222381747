import classNames from 'classnames';
import React, { FC } from 'react';

import { formatNumber } from '@/utility/helpers';
import { t } from '@/utility/localization';

import { getPriceText } from '@/pages/portal/utils';

import NPLBadge from '../npl/NPLBadge';

export type PricingConfigType = {
  amount: number;
  currency: string;
  minAmount: number;
  priceType: 'FLEXIBLE' | 'FIXED';
  suggestedAmount: number;
};

type Props = {
  currency: string;
  darkMode: boolean;
  isManagementPage: boolean;
  pricingConfig: PricingConfigType;
  discountedPrice?: number;
  displayType?: 'md' | 'sm';
  wrap?: boolean;
};

const PortalEntityPriceDisplayTag: FC<Props> = ({
  currency,
  pricingConfig,
  displayType = 'md',
  darkMode = false,
  isManagementPage = false,
  wrap,
  discountedPrice = null
}) => {
  const { amount, suggestedAmount, priceType } = pricingConfig || {};

  const isFlexiblePriceType = priceType === 'FLEXIBLE';

  const getPricingText = (): string | React.JSX.Element => {
    // if suggested amount does not exist, use amount (for money tab case)
    if (priceType === 'FLEXIBLE') {
      return `${currency?.toUpperCase()} ${formatNumber(suggestedAmount ?? amount, true, true)}`;
    }
    if (amount == 0) {
      return t('free');
    }
    const priceText = getPriceText(amount, discountedPrice, currency);
    return priceText;
  };

  if (isManagementPage) {
    return (
      <div
        className={classNames('flex ', {
          'flex-col gap-4': wrap,
          'items-center gap-8': !wrap
        })}>
        <NPLBadge
          text={getPricingText()}
          type="info"
          size="sm"
          darkMode={darkMode}
        />
        {isFlexiblePriceType && (
          <div className="uppercase flex">
            <NPLBadge
              size="xs"
              type="info"
              text={t('suggested')}
              darkMode={darkMode}
            />
          </div>
        )}
      </div>
    );
  }
  return (
    <div
      className={classNames('flex ', {
        'flex-col gap-4': wrap,
        'items-center gap-8': !wrap
      })}>
      <div
        className={classNames({
          'text-label-md font-medium': displayType === 'md',
          'text-label-sm': displayType === 'sm'
        })}>
        <NPLBadge
          size="sm"
          type="outline"
          text={getPricingText()}
          darkMode={darkMode}
        />
      </div>
      {isFlexiblePriceType && (
        <div className="uppercase flex">
          <NPLBadge
            size="xs"
            type="info"
            text={t('suggested')}
            darkMode={darkMode}
          />
        </div>
      )}
    </div>
  );
};

export default PortalEntityPriceDisplayTag;
